<template>
  <div id="page-content" class="rich_media_area_primary">
    <Head ref="head"></Head>
    <div class="rich_media_area_primary_inner">
      <h1 class="rich_media_title " id="activity-name">
        {{ title }}
      </h1>
      <div v-html="content"></div>
    </div>
  </div>
</template>

<script>
import { get } from "../utils/request";
import Head from "../views/components/HeadNoMenu.vue";
export default {
   components: {
    Head,
  },
  data() {
    return {
      id: "",
      title: "",
      content: "",
    };
  },
  mounted() {
    this.id = localStorage.newsId;
    this.getNewsOne();
  },
  methods: {
    getNewsOne() {
      let url = "/api/getNewsOne?id=" + this.id;
      get(url).then((res) => {
        if (res.status == 200) {
          this.title = res.data.data.news;
          this.content = res.data.data.newsContent;
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
.rich_media_area_primary {
  background-color: #f2f6ff;
  height: 100vh;
  display: flex;
  justify-content: center;
  color: #000;
}
.rich_media_area_primary_inner {
  margin-top: 60px;
  overflow: auto;
  width: 677px;
  text-align: left;
  background-color: #fff;
  padding: 10px 26px 10px 26px;
}
.rich_media_title {
  font-size: 22px;
  line-height: 1.4;
  margin-bottom: 14px;
}

::-webkit-scrollbar-thumb {
  cursor: pointer;
  background-color: #808080;
  border-radius: 5px;
}
::-webkit-scrollbar {
  width: 2px;
  height: 5px;
}
</style>
